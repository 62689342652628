<template>
  <v-container fluid px-3 py-3 grid-list-lg>
    <div>
      <browser-alert :fullWidth="true" />
    </div>
    <detail-recording-dialog
      :recording="validActiveRecording"
      :value="dialog === 'detail'"
      @onClose="onClose"
    />
    <d-panel-toolbar title="Analytics" dark>
      <v-spacer></v-spacer>
      <v-toolbar-items
        color="primary"
        v-if="isAuthorized('operator') && supportedExportType"
      >
        <v-btn flat @click="download">
          {{ `Download CSV ${reportName} Report` }}
          <v-icon right>save</v-icon>
        </v-btn>
      </v-toolbar-items>
    </d-panel-toolbar>
    <timeframe :timezone="timezone" />
    <v-tabs dark slider-color="primary" v-model="activeReport" show-arrows>
      <v-tab ripple v-for="r in reports" :key="`report-tab-${r.type}`">
        {{ r.name }}
      </v-tab>
      <v-tab-item
        v-for="(r, index) in reports"
        :key="`report-tab-item-${r.type}`"
      >
        <component
          v-if="activeReport === index"
          :chartColumnWidth="chartColumnWidth"
          :is="r.component"
          :timezone="timezone"
          :report="r.data"
          :meta="meta"
          @playRecording="playRecording($event)"
          @downloadRecording="downloadRecording($event)"
        ></component>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import {DPanelToolbar} from '@/components/Base'
import BrowserAlert from '@/components/Base/BrowserAlert.vue'
import moment from 'moment-timezone'

import {
  EventReport,
  AlertReport,
  DisruptionReport,
  Timeframe,
  CameraRecordings,
  DetailRecordingDialog,
  SiteLogsReport
} from '@/components/EventReport'

export default {
  name: 'ReportPage',
  data: () => ({
    shownReports: [],
    activeReport: 0,
    chartColumnWidth: 3,
    activeRecording: null,
    dialog: false,
    reportList: [
      'event_summary',
      'cannon_disruption',
      'alerts',
      'camera_recordings'
    ]
  }),
  components: {
    DPanelToolbar,
    EventReport,
    AlertReport,
    DisruptionReport,
    Timeframe,
    CameraRecordings,
    DetailRecordingDialog,
    SiteLogsReport,
    BrowserAlert
  },
  computed: {
    ...mapGetters('sensors', ['meta', 'recordingsSet']),
    ...mapGetters('users', ['isAuthorized', 'isAdmin']),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('sites', ['activeSite']),
    ...mapState(['offline']),
    instanceOffline() {
      return this.offline
    },
    reports() {
      const reportTabs = [
        {
          name: 'Tracks Summary',
          type: 'event_summary',
          component: 'event-report',
          data: {}
        },
        {
          name: 'Disruptions Summary',
          type: 'cannon_disruption',
          component: 'disruption-report',
          data: []
        }
      ]

      if (!this.instanceOffline) {
        reportTabs.push({
          name: 'Alerts Summary',
          type: 'alerts',
          component: 'alert-report',
          data: []
        })
      }

      reportTabs.push({
        name: 'Video recordings',
        type: 'camera_recordings',
        component: 'camera-recordings',
        data: []
      })

      if (this.isAdmin || this.isAuthorized('site_manager')) {
        reportTabs.push({
          name: 'Site Logs',
          type: 'site_logs',
          component: 'site-logs-report',
          data: []
        })
      }

      return reportTabs
    },
    timezone() {
      return this.activeSite && this.activeSite.timezone
    },
    reportType() {
      return (
        this.reports[this.activeReport] && this.reports[this.activeReport].type
      )
    },
    reportName() {
      return (
        this.reports[this.activeReport] && this.reports[this.activeReport].name
      )
    },
    downloadParams() {
      return (format = 'json') => {
        // const { type } = this.reports[this.activeReport]
        return {
          site_id: this.activeSiteId,
          format,
          type: this.reportType,
          ...this.range,
          timezone: this.timezone,
        }
      }
    },
    // TODO: Remove once camera recordings reports have been fixed
    supportedExportType() {
      return this.reportType !== 'camera_recordings'
    },
    validActiveRecording() {
      return (
        this.recordingsSet[this.activeRecording?.id] || this.activeRecording
      )
    }
  },
  methods: {
    ...mapActions('reports', {
      getReport: 'FETCH_REPORTS'
    }),
    ...mapActions('sensors', {
      fetchRecording: 'FETCH_AND_LOAD_RECORDING'
    }),
    byHourTZFormating(data) {
      let emptyReport = new Array(24).fill(0)
      for (let i = 0; i < data.by_hour.length; i++) {
        if (data.by_hour[i] && data.by_hour[i] > 0) {
          let utcHour = moment.utc().hour(i)
          let tzHour = moment.tz(utcHour, this.timezone).hour()
          emptyReport[tzHour] = data.by_hour[i]
        }
      }
      data.by_hour = emptyReport
    },
    async load() {
      let data

      if (this.reportType === 'radar') return

      data = await this.getReport(this.downloadParams())

      if (this.reportType === 'event_summary' && data.by_hour) {
        this.byHourTZFormating(data)
      }

      this.reports[this.activeReport].data = data
      await this.$store.dispatch('sensors/FETCH_RECORDINGS')
    },
    async download() {
      this.$emitter.emit('downloadReport')
    },
    async playRecording(recordingId) {
      const recording = await this.fetchRecording(recordingId)
      this.activeRecording = recording
      this.dialog = 'detail'
    },
    async downloadRecording(recordingId) {
      const recording = await this.fetchRecording(recordingId)
      window.location.href = document.location.origin + recording.download_url
    },
    onClose() {
      this.activeRecording = null
      this.dialog = false
    }
  },
  mounted() {
    // TODO: Bring back real-time recordings update
    // this.$bus.$on('SOCKET/RECORDINGS_UPDATE', payload => {
    //   const { data } = payload.message
    //   return this.$store.dispatch('sensors/SOCKET_RECORDINGS_UPDATE', data)
    // })
    // this.$bus.$on('SOCKET/RECORDINGS_CREATE', payload => {
    //   const { data } = payload.message
    //   return this.$store.commit('sensors/SOCKET_RECORDINGS_CREATE', data)
    // })
    // this.$bus.$on('SOCKET/RECORDINGS_DELETE', payload => {
    //   const { data } = payload.message
    //   return this.$store.commit('sensors/SOCKET_RECORDINGS_DELETE', data)
    // })
  },
  beforeDestroy() {
    // TODO: Bring back real-time recordings update
    // this.$bus.$off('SOCKET/RECORDINGS_UPDATE')
    // this.$bus.$off('SOCKET/RECORDINGS_CREATE')
    // this.$bus.$off('SOCKET/RECORDINGS_DELETE')
  }
}
</script>
